<template>
	<div class="container">
		<router-view v-if="getAvailableMethodsFetchStatus.success && wallet.success && !wallet.data.isDemo" />

		<demo-deposit v-if="wallet.success && wallet.data.isDemo" />

		<div v-if="getAvailableMethodsFetchStatus.loading || wallet.loading" class="center">
			<Loader />
		</div>

		<div v-if="getAvailableMethodsFetchStatus.error || wallet.error" class="error-container">
			<ErrorCard :error="getAvailableMethodsFetchStatus.error || wallet.error" />
		</div>

	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import apiClient from '@/api';
import Loader from '@/shared/loader/Loader';
import ErrorCard from '@/shared/error-card/ErrorCard';
import DemoDeposit from '@/components/payments/deposit/payment-methods/Demo.vue';

export default {
	name: 'Deposit',
	components: { DemoDeposit, ErrorCard, Loader },
	computed: {
		...mapGetters(['getAvailableMethodsFetchStatus']),
	},
	data() {
		return {
			/** the select wallet for the deposit */
			wallet: {
				loading: false,
				success: false,
				error: null,
				data: null,
			},
		};
	},
	created() {
		this.fetchWallet();
		// TODO: This should be inside `DepositSelect`, not here. It also does not need to be a
		// VueX state then but can simply be fetched and processed there.
		this.fetchAvailablePaymentMethods();
	},
	methods: {
		...mapActions(['fetchAvailablePaymentMethods']),
		async fetchWallet() {
			this.wallet.loading = true;
			try {
				const wallet = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.wallet.data = wallet;
				this.wallet.success = true;
			} catch (e) {
				this.wallet.error = e;
				Sentry.captureException(e);
			} finally {
				this.wallet.loading = false;
			}
		},
	},
};
</script>

<style lang="scss">
.container {
	flex: 1;
}

.center {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.error-container {
	margin-top: 4rem;
}
</style>
